.add-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  filter: drop-shadow(0px 5px 30px rgba(43, 135, 218, 0.2));
  border-radius: 5px;
  max-width: 280px;
  width: 100%;
  max-height: 380px;
  overflow: auto;
  background: #fff;
  z-index: 1;
}
