.limit-reached {
  padding: 15px;
  background: #f1f5f9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.limit-reached-icon {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background: #F12424;
}