.site-card {
  width: 50%;
  background-color: white;
  border-radius: 5px;
  padding: 24px 16px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}
.site-bar {
  height: 6px;
  width: 100%;
  background-color: #f1f5f9;
  border-radius: 5px;
  margin-top: 10px;
}
.site-bar>div {
  height: 6px;
  background-color: #0066ff;
  border-radius: 5px;
  transition: all .3s ease-in-out;
}
