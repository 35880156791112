.main-menu-list {
  display: flex;
  background-color: #252d32;
  padding: 0px 24px;
}
.main-menu-list-tab {
  padding: 6px 10px;
  margin: 6px 2.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.main-menu-list-tab:hover {
  background-color: #1d2327;
}
.main-menu-list-tab > h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
}

.active-menu {
  background-color: #364149;
}
.active-menu:hover {
  background-color: var(--theme-gray);
}
