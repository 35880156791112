.main-header {
  background-color: #1d2327;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 20px;
}
.header-left-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon-container {
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 6px;
}
.header-filled-icon-container {
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F1F5F9;
  border-radius: 5px;
}
.header-filled-icon-container h2 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
.rounded-full {
  border-radius: 50%;
}
.header-user-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 8px;
}
.main-body-containter {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
  min-height: calc(100vh - 90px); 
  /* changed clac from 58px to 90px */
  padding-top: 28px;

}
.main-body-wrapper {
  width: 100%;
  height: 100%;
  max-width: 1200px;
}
.main-body-header {
  background-color: #1d2327;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13.5px 24px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.left-side-container{
    background-color: white;
    padding: 24px;
    max-width: 22.5rem;
    width: 100%;
    min-height: 40vh;
    height: 72vh;
    /* changed height from 75 to 72 */
    overflow-y: auto;
}
.header-user-dropdown{
  position: absolute;
  top: 100%;
  background-color: white;
  right: -10%;
  border-radius: 5px;
  box-shadow: 0px 5px 20px rgba(0, 160, 210, 0.2);
  padding: 8px;
  user-select: none;
  min-width: 208px;
}
.header-dropdown-content{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px 12px;
}
.header-dropdown-content:hover{
  background: #0066ff;
  border-radius: 5px;
}
.header-dropdown-content:hover span{
  color: white;
}
.header-dropdown-content path{
  fill: #0066ff;
}
.header-dropdown-content:hover path{
  fill: white;
}
.header-logo{
  width: 220px;
  height: fit-content;
}
.dropdown-click-block{
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1;
  background: transparent;
  cursor: pointer;
}