.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f1f5f9;
  overflow-y: auto;
}
:is(.login-form, .signup-form) {
  background-color: white;
  padding: 50px 70px;
  box-shadow: 0px 5px 30px rgba(43, 135, 218, 0.2);
  border-radius: 5px;
}
.login-form input {
  min-width: 376px;
  padding: 7px 0px;
}
.signup-form input {
  min-width: 376px;
  padding: 7px 0px;
}
.login-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  text-align: center;
  color: #1d2327;
  margin-bottom: 40px;
}
.forget-link {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0066ff;
}
.signup-link {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #1d2327;
}
.forget-details {
  max-width: 400px;
  text-align: center;
  padding: 0px 0px;
  margin-bottom: 0px;
}
.mb-12 {
  margin-bottom: 3rem /* 48px */;
}
.logo-size {
  width: 373px;
}

.workspace-container {
  max-height: 70vh;
  height: 100%;
  overflow-y: auto;
  width: 80%;
  margin: auto;
}

.workspace-container-proceed-button {
  float: right;
}
